<template>
  <el-menu
    :default-active="defaultId"
    :default-openeds="openIds"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    @select="selectSub"
  >
    <template v-for="item in list">
      <sub-item :item="item"></sub-item>
    </template>
  </el-menu>
</template>

<script>
import subItem from "./item";

export default {
  name: "subMenu",
  props: {
    list: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    openIds: {
      type: Array,
      default: () => {
        return [];
      }
    },
    defaultId: {
      type: [String, Number],
      required: true,
      default: ""
    }
  },
  components: { subItem },
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    selectSub(key, keyPath) {
      this.$emit("selectSub", key, keyPath);
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
>>>.el-menu-item
>>>.el-submenu__title
    display: flex
    align-items: center
>>>.el-menu-item svg
>>>.el-submenu__title svg
    margin-right 5px
>>>.el-menu-item.is-active svg path
    fill: #409EFF !important
</style>
