<template>
  <div>
    <el-submenu
      :index="item.id"
      v-if="item.children && item.children.length > 0"
    >
      <template>
        <template slot="title">
          <svgName
            v-if="item.svgName"
            :name="item.svgName"
            width="16"
            height="16"
            color="#909399"
          ></svgName>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="1-1">1.背景介绍</el-menu-item> -->
          <subItem
            v-for="it in item.children"
            :key="it.id"
            :item="it"
          ></subItem>
        </el-menu-item-group>
      </template>
    </el-submenu>
    <el-menu-item :index="item.id" v-else>
      <svgName
        v-if="item.svgName"
        :name="item.svgName"
        width="16"
        height="16"
        color="#909399"
      ></svgName>

      <span slot="title">{{ item.name }}</span>
    </el-menu-item>
  </div>
</template>

<script>
import svgName from "@/components/svgIndex";
export default {
  name: "subItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  components: { svgName },
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>
<style lang="stylus" scoped></style>
