<template>
  <span class="flex-center svg-box">
    <svg
      v-if="name == 'tcgl'"
      t="1678101219005"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2793"
      :width="width"
      :height="height"
    >
      <path
        d="M512 526.7c-5.6 0-11.2-1.1-16.4-3.2-0.1 0-0.2-0.1-0.3-0.1l-404.9-166C76.8 351.9 68 338.7 68 324.1s8.9-27.8 22.5-33.3L495.3 127c10.6-4.4 22.7-4.4 33.3 0l404.9 163.9c13.6 5.5 22.5 18.7 22.5 33.3 0 14.6-8.8 27.8-22.3 33.4l-404.9 166c-0.3 0.1-0.6 0.2-0.8 0.3-5.2 1.9-10.6 2.8-16 2.8zM199.4 324.4L512 452.5l312.6-128.1L512 197.9 199.4 324.4z m323.6-131c-0.1 0-0.1 0.1-0.2 0.1 0 0 0.1 0 0.2-0.1z m-22 0s0.1 0 0 0c0.1 0 0 0 0 0z"
        :fill="color"
        p-id="2794"
      ></path>
      <path
        d="M512 713.7c-5.7 0-11.4-1.1-16.8-3.3l-404.8-166c-18.4-7.5-27.2-28.6-19.7-47s28.6-27.2 47-19.7L512 639.5l394.2-161.6c18.4-7.5 39.4 1.3 47 19.7 7.5 18.4-1.3 39.4-19.7 47l-404.8 166c-5.3 2-11 3.1-16.7 3.1z m10.9-69.8c0 0.1 0 0.1 0 0 0 0.1 0 0.1 0 0z"
        :fill="color"
        p-id="2795"
      ></path>
      <path
        d="M512 900.3c-5.7 0-11.4-1.1-16.8-3.3L90.4 731c-18.4-7.5-27.2-28.6-19.7-47s28.6-27.2 47-19.7L512 826.1l394.2-161.6c18.4-7.5 39.4 1.3 47 19.7 7.5 18.4-1.3 39.4-19.7 47L528.7 897c-5.3 2.2-11 3.3-16.7 3.3z m-10.8-69.8s-0.1 0-0.1 0.1c0 0 0-0.1 0.1-0.1z m21.6 0s0.1 0 0.1 0.1c0-0.1 0-0.1-0.1-0.1z"
        :fill="color"
        p-id="2796"
      ></path>
    </svg>
    <svg
      t="1678103714734"
      v-else-if="name == 'sxmb'"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3841"
      :width="width"
      :height="height"
    >
      <path
        d="M830.135395 77.043416H207.699478c-36.246823 0-65.630008 34.914735-65.630008 77.98498V894.643571c0 43.069538 29.383538 77.984274 65.630008 77.984274h622.435917c36.24647 0 65.630008-34.914735 65.630008-77.984274V155.028043c0-43.070244-29.383538-77.984627-65.630008-77.984627z m-2.803875 755.815421c0 40.602919-32.913782 73.514231-73.51282 73.514231H281.283206c-40.601508 0-73.514231-32.913782-73.514231-73.514231V206.314826c0-40.600802 32.912723-73.513525 73.514231-73.513525h472.535847c40.600449 0 73.51282 32.912371 73.51282 73.513525v626.544011z"
        :fill="color"
        p-id="3842"
      ></path>
      <path
        d="M782.61063 345.875696v-10.321563c0-17.369702-14.081464-31.452225-31.451166-31.452224H389.692417c-17.36935 0-31.451872 14.081464-31.451872 31.451872v10.321915c0 17.370761 14.081464 31.451167 31.451872 31.451166h361.467047c17.369702-0.000353 31.451167-14.080406 31.451166-31.451166zM784.829247 539.769228v-10.32262c0-17.370055-14.081111-31.451519-31.451166-31.451519h-361.466342c-17.369702 0-31.452225 14.080406-31.452224 31.451519v10.32262c0 17.36935 14.081464 31.451167 31.452224 31.451167h361.466342c17.370055-0.001411 31.451167-14.081817 31.451166-31.451167zM784.829247 731.284336v-10.322973c0-17.369702-14.081111-31.452225-31.451166-31.452225h-361.466342c-17.369702 0-31.452225 14.081464-31.452224 31.452225v10.322973c0 17.369702 14.081464 31.450814 31.452224 31.450814h361.466342c17.370055-0.002117 31.451167-14.081111 31.451166-31.450814z"
        :fill="color"
        p-id="3843"
      ></path>
      <path
        d="M289.98305 341.260661m-36.625 0a36.625 36.625 0 1 0 73.25 0 36.625 36.625 0 1 0-73.25 0Z"
        :fill="color"
        p-id="3844"
      ></path>
      <path
        d="M289.98305 534.607389m-36.625 0a36.625 36.625 0 1 0 73.25 0 36.625 36.625 0 1 0-73.25 0Z"
        :fill="color"
        p-id="3845"
      ></path>
      <path
        d="M289.98305 726.121791m-36.625 0a36.625 36.625 0 1 0 73.25 0 36.625 36.625 0 1 0-73.25 0Z"
        :fill="color"
        p-id="3846"
      ></path>
    </svg>
    <svg
      t="1678103913902"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4228"
      v-else-if="name == 'htgj'"
      :width="width"
      :height="height"
    >
      <path
        d="M980.8 438.4c-1.6-15.2-8-29.6-20-39.2l-79.2-68c-24-20-60-17.6-80 5.6l-360 416c-5.6 7.2-10.4 16-12 24v0.8L412.8 880l-0.8 2.4c-3.2 29.6 16.8 56.8 46.4 63.2 1.6 0 3.2 0.8 4.8 0.8 7.2 0.8 14.4 0 20.8-1.6l98.4-32.8c8.8-2.4 16.8-7.2 23.2-13.6L968 480c8-11.2 13.6-26.4 12.8-41.6z m-428 400l-56.8 19.2 10.4-57.6 252.8-292.8 45.6 40.8-252 290.4zM856 488l-45.6-40.8 36-41.6 46.4 40L856 488z"
        p-id="4229"
        :fill="color"
      ></path>
      <path
        d="M148 809.6V214.4c31.2-11.2 56-35.2 66.4-66.4h594.4c15.2 42.4 55.2 73.6 103.2 73.6 60 0 109.6-48.8 109.6-109.6S972.8 2.4 912 2.4c-47.2 0-88 30.4-103.2 72H214.4C200 32 159.2 1.6 111.2 1.6 51.2 1.6 2.4 51.2 2.4 111.2c0 47.2 30.4 88 72.8 103.2v595.2c-42.4 15.2-72.8 55.2-72.8 103.2 0 60 48.8 109.6 109.6 109.6 48 0 88-30.4 103.2-72.8h79.2v-72.8h-79.2c-11.2-31.2-36-56-67.2-67.2z"
        p-id="4230"
        :fill="color"
      ></path>
    </svg>
    <svg
      t="1678104019549"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4468"
      v-else-if="name == 'tzpz'"
      :width="width"
      :height="height"
    >
      <path
        d="M531.626667 72.192L200.533333 355.072c-10.965333 9.386667-18.133333 22.442667-20.053333 36.736l-40.96 300.544a58.794667 58.794667 0 0 0 61.525333 66.645333l303.616-16.64c14.890667-0.853333 28.885333-7.253333 39.210667-18.005333l318.72-331.861333a58.837333 58.837333 0 0 0-2.389333-83.2l-250.154667-235.221334a58.794667 58.794667 0 0 0-78.464-1.877333z m37.76 60.458667l233.770666 219.818666-306.986666 319.658667-284.8 15.616 38.4-281.984 319.616-273.109333zM193.152 839.466667l596.266667 1.28a33.92 33.92 0 0 1 4.096 67.584l-4.266667 0.256-596.266667-1.28a33.92 33.92 0 0 1-4.096-67.584l4.266667-0.256z"
        :fill="color"
        p-id="4469"
      ></path>
    </svg>

    <svg
      t="1678104131287"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="6548"
      v-else-if="name == 'scroll'"
      :width="width"
      :height="height"
    >
      <path d="M704 64h256v64h-256z" p-id="6549" :fill="color"></path>
      <path
        d="M896 64h64v256h-64zM66.688 64h64v256h-64z"
        :fill="color"
        p-id="6550"
      ></path>
      <path
        d="M66.688 64h256v64h-256zM66.688 894.976h256v64h-256z"
        p-id="6551"
        :fill="color"
      ></path>
      <path
        d="M66.688 702.976h64v256h-64zM896 702.976h64v256h-64z"
        p-id="6552"
        :fill="color"
      ></path>
      <path d="M704 894.976h256v64h-256z" :fill="color" p-id="6553"></path>
    </svg>
    <svg
      t="1678104869730"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="9579"
      v-else-if="name == 'line'"
      :width="width"
      :height="height"
    >
      <path
        d="M827.076923 157.538462a39.384615 39.384615 0 1 1-14.375385 76.051692L233.590154 812.701538a39.384615 39.384615 0 1 1-22.291692-22.291692L790.409846 211.298462A39.384615 39.384615 0 0 1 827.076923 157.538462z"
        :fill="color"
        p-id="9580"
      ></path>
    </svg>

    <svg
      t="1678105271406"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="10338"
      v-else-if="name == 'polygon'"
      :width="width"
      :height="height"
    >
      <path
        d="M854.6176 1024v-114.2272H169.3824V1024H10.3552v-159.0016h114.1504V159.0272H10.3552V0h159.0272v114.688h685.2352V0h159.0272v159.0272H899.1872v705.9712h114.4576V1024zM179.6736 854.5024h664.3456V169.856H182.592z"
        :fill="color"
        p-id="10339"
      ></path>
    </svg>
    <svg
      t="1678105367470"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="11298"
      v-else-if="name == 'circle'"
      :width="width"
      :height="height"
    >
      <path
        d="M510.44304 940.315042c-237.326197 0-430.396447-193.21249-430.396447-430.695253 0-237.502206 193.07025-430.717765 430.396447-430.717765 237.303684 0 430.40668 193.236026 430.40668 430.717765C940.84972 747.101529 747.791749 940.315042 510.44304 940.315042zM510.44304 124.414591c-212.213239 0-384.861368 172.782182-384.861368 385.182686 0 212.403574 172.647105 385.161197 384.861368 385.161197 212.224496 0 384.870578-172.782182 384.870578-385.161197C895.314641 297.196773 722.667535 124.414591 510.44304 124.414591z"
        :fill="color"
        p-id="11299"
      ></path>
    </svg>
    <svg
      t="1678105474738"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="11795"
      v-else-if="name == 'square'"
      :width="width"
      :height="height"
    >
      <path
        d="M885.333333 938.666667H138.666667a53.393333 53.393333 0 0 1-53.333334-53.333334V138.666667a53.393333 53.393333 0 0 1 53.333334-53.333334h746.666666a53.393333 53.393333 0 0 1 53.333334 53.333334v746.666666a53.393333 53.393333 0 0 1-53.333334 53.333334zM138.666667 128a10.666667 10.666667 0 0 0-10.666667 10.666667v746.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h746.666666a10.666667 10.666667 0 0 0 10.666667-10.666667V138.666667a10.666667 10.666667 0 0 0-10.666667-10.666667z"
        :fill="color"
        p-id="11796"
      ></path>
    </svg>
    <svg
      t="1678105941239"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="17931"
      v-else-if="name == 'text'"
      :width="width"
      :height="height"
    >
      <path
        d="M692.2597343921661 882.78857421875H331.711297750473v-30.689996480941772a563.3140236139297 563.3140236139297 0 0 1 48.088091611862176-14.83154296875c16.200274229049683-4.278552532196046 33.656305074691765-7.757592201232912 52.39561200141907-10.638445615768433v-630.340576171875h-136.73552870750427l-57.244107127189636 151.53810381889343H211.2339789867401c-2.1675199270248413-14.118209481239319-3.9642512798309326-30.233752727508545-5.419161915779114-48.23148250579833-1.4541864395141602-17.939794063568115-2.6809751987457275-36.30831241607666-3.7933409214019784-55.10410666465761-1.1123657226562498-18.59736442565918-1.9683659076690674-37.078857421875-2.6527315378189087-55.019375681877136C198.6264432668686 171.53063368797297 198.2563788890838 155.35860311985022 198.2563788890838 141.21142578125h627.4886906147003c0 14.147177338600159-0.3997564315795899 30.090361833572388-1.1123657226562498 47.689059376716614-0.7415771484374999 17.655909061431885-1.6547888517379765 35.852792859077454-2.766430377960205 54.56313192844391s-2.139276266098023 37.078857421875-3.251641988754273 55.10410666465761c-1.0841220617294314 17.998453974723816-2.7099430561065674 34.48406159877777-4.9064308404922485 49.28736090660096h-28.408053517341607l-56.75889551639557-151.53810381889343H592.8891206979752V826.6850764751434c18.71033906936645 3.5659432411193848 36.22285723686218 7.07395076751709 52.366644144058235 10.638445615768433 16.229242086410522 3.508731722831726 31.85957372188568 8.415162563323973 47.00469374656678 14.83154296875v30.633509159088135z"
        :fill="color"
        p-id="17932"
      ></path>
    </svg>
    <svg
      t="1678105885719"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="16936"
      v-else-if="name == 'back'"
      :width="width"
      :height="height"
    >
      <path
        d="M365.44 112.32v133.76h237.888a340.928 340.928 0 0 1 340.928 335.36v5.632a340.928 340.928 0 0 1-335.296 340.864l-5.632 0.064h-324.48a16 16 0 0 1-16-16v-64a16 16 0 0 1 16-16h324.48a244.928 244.928 0 0 0 5.12-489.856H130.432a16 16 0 0 1-11.136-27.52l218.88-213.76a16 16 0 0 1 27.2 11.456z"
        :fill="color"
        p-id="16937"
      ></path>
    </svg>
    <svg
      t="1678105825973"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="15996"
      v-else-if="name == 'next'"
      :width="width"
      :height="height"
    >
      <path
        d="M670.08 112.32v133.76H432.192a340.928 340.928 0 0 0-340.928 335.36v5.632a340.928 340.928 0 0 0 335.296 340.864l5.632 0.064h324.48a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16h-324.48a244.928 244.928 0 0 1-5.12-489.856h477.952a16 16 0 0 0 11.2-27.52l-219.008-213.76a16 16 0 0 0-27.136 11.456z"
        :fill="color"
        p-id="15997"
      ></path>
    </svg>
    <svg
      t="1678883158643"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1757"
      v-else-if="name == 'dAdd'"
      :width="width"
      :height="height"
    >
      <path
        d="M128 128v768h768V128H128zM96 64h832a32 32 0 0 1 32 32v832a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32z"
        :fill="color"
        p-id="1758"
      ></path>
      <path
        d="M554.144 554.56v228.608h-85.568V554.56H239.936v-85.6h228.64V237.76h85.568v231.168h231.2v85.6h-231.2z"
        :fill="color"
        p-id="1759"
      ></path>
    </svg>
    <svg
      t="1678883307251"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1898"
      v-else-if="name == 'dEdit'"
      :width="width"
      :height="height"
    >
      <path
        d="M775.84 392.768l-155.2-172.352L160.768 643.264l-38.368 187.936 190.56-12.832zM929.952 229.952l-131.2-150.944-0.288-0.32a16 16 0 0 0-22.592-0.96l-131.168 120.576 155.168 172.352 128.832-118.464a15.936 15.936 0 0 0 1.248-22.24zM96 896h832v64H96z"
        p-id="1899"
        :fill="color"
      ></path>
    </svg>
    <svg
      t="1679108403364"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3729"
      v-else-if="name == 'czsc'"
      :width="width"
      :height="height"
    >
      <path
        d="M341.333333 85.333333v853.333334H170.666667v-170.666667H85.333333V682.666667H170.666667V554.666667H85.333333v-85.333334H170.666667V341.333333H85.333333V256H170.666667v-170.666667h170.666666z m512.227556 0c46.990222 0 85.105778 38.343111 85.105778 84.935111v683.52c0 46.876444-38.115556 84.878222-85.105778 84.878223H426.666667v-853.333334h426.894222z"
        :fill="color"
        p-id="3730"
      ></path>
    </svg>

    <svg
      t="1679109012415"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5938"
      v-else-if="name == 'czsm'"
      :width="width"
      :height="height"
    >
      <path
        d="M512 0c-282.751 0-512 229.247-512 512 0 282.784 229.247 512 512 512s512-229.216 512-512c0-282.751-229.247-512-512-512zM800 640h-256v160h-64v-160h-256v-64h256v-96h-224v-64h162.72l-152.544-152.576 45.28-45.28 190.4 190.4-7.456 7.456h35.232l-7.424-7.424 190.4-190.4 45.28 45.28-152.608 152.544h162.72v64h-224v96h256v64z"
        :fill="color"
        p-id="5939"
      ></path>
    </svg>
    <svg
      t="1681008880152"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="851"
      v-else-if="name == 'bjgj'"
      :width="width"
      :height="height"
    >
      <path
        d="M765.38074453 739.9931904c0 80.17892693-63.18871893 145.2310528-141.08480853 145.2310528H216.71881387c-77.88516693 0-141.0793472-65.0510336-141.0793472-145.2310528V320.45684053c0-80.17892693 63.19418027-145.22996053 141.0793472-145.22996053h407.57602986c19.60072533 0 39.19162027 4.03592533 57.3177856 12.60475733 4.40183467 2.02069333 7.84247467 6.55906133 8.82005334 11.599872 0.97757867 5.54325333-0.49152 10.58952533-4.41821867 14.61998934l-23.99709867 24.71253333c-4.407296 4.538368-10.289152 6.05115733-15.67402666 4.03592533-7.35095467-2.02069333-14.70190933-3.03104-22.04740267-3.03104H216.71881387c-43.10739627 0-78.37668693 36.3134976-78.37668694 80.68683094v419.5418112c0 44.3744256 35.27038293 80.67590827 78.37668694 80.67590826h407.57602986c43.1128576 0 78.38214827-36.30257493 78.38214827-80.67590826v-63.53824427c0-4.03592533 1.46909867-8.06638933 4.407296-11.091968l31.3491456-32.27211093c4.898816-5.04081067 11.26673067-6.05661867 17.14858667-3.53348267 5.87639467 2.523136 9.797632 8.06638933 9.797632 14.62545067v95.80598613z m94.05617494-322.72766293L530.24413013 756.1314304H389.15822933V610.90146987l329.18842027-338.8604416 141.09026987 145.2244992z m-291.96288 231.9581184l-74.46091094-76.63998294-56.8262656 58.4974336v28.23509334h47.02317227v48.4048896h27.43773867l56.8262656-58.4974336z m142.54953813-304.06519467L538.56720213 521.650176c-4.407296 4.538368-4.89335467 12.10231467-0.48605866 16.64068267s11.75825067 4.03592533 16.16008533-0.50244267l171.45637547-176.4917248c4.407296-4.538368 4.90427733-12.10231467 0.49698133-16.64068267-4.41275733-4.538368-11.75825067-4.030464-16.171008 0.50244267z m180.76248747 39.8360576L749.70671787 239.76891733l45.06801493-46.3896576c18.12616533-18.661376 48.49773227-18.661376 66.6238976 0l74.4554496 76.64544427c18.13162667 18.661376 18.13162667 49.92314027 0 68.57905493l-45.06801493 46.39074987z m0 0"
        :fill="color"
        p-id="852"
      ></path>
    </svg>
    <svg
      t="1681010534480"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1856"
      v-else-if="name == 'move'"
      :width="width"
      :height="height"
    >
      <path d="M492 136h40v232.6h-40z" :fill="color" p-id="1857"></path>
      <path
        d="M391.6 234.2l-22.9-32.8L514.4 99.6l136.7 102.2-24 32-113.5-84.9zM493.3 660.9h40v232.6h-40z"
        :fill="color"
        p-id="1858"
      ></path>
      <path
        d="M510.8 930L374.2 827.8l23.9-32.1 113.5 84.9 122.1-85.3 22.9 32.8zM658.9 492.6h232.6v40H658.9z"
        :fill="color"
        p-id="1859"
      ></path>
      <path
        d="M825.7 651.7l-32-24 84.9-113.5-85.3-122 32.8-23L927.9 515zM134.6 492.6h232.6v40H134.6z"
        :fill="color"
        p-id="1860"
      ></path>
      <path
        d="M200.4 651.7L98.2 515 200 369.2l32.8 23-85.3 122 84.9 113.5z"
        :fill="color"
        p-id="1861"
      ></path>
      <path
        d="M512 512.1m-40 0a40 40 0 1 0 80 0 40 40 0 1 0-80 0Z"
        :fill="color"
        p-id="1862"
      ></path>
    </svg>
    <svg
      t="1681010606244"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2801"
      v-else-if="name == 'zoom'"
      :width="width"
      :height="height"
    >
      <path
        d="M1002.600792 1015.551362H654.357549c-11.070422 0-20.044219-8.973797-20.044219-20.044219s8.973797-20.044219 20.044219-20.044218h328.199025V45.93734H53.030989v346.613649c0 11.070422-8.973797 20.044219-20.044218 20.044219s-20.044219-8.973797-20.044219-20.044219V25.893122c0-11.070422 8.973797-20.044219 20.044219-20.044219h969.614021c11.070422 0 20.044219 8.973797 20.044219 20.044219v969.614021c0 11.06942-8.973797 20.044219-20.044219 20.044219z"
        :fill="color"
        p-id="2802"
      ></path>
      <path
        d="M517.794283 1017.123831H32.986771c-11.070422 0-20.044219-8.973797-20.044219-20.044219V512.273102c0-11.070422 8.973797-20.044219 20.044219-20.044218h484.807512c11.070422 0 20.044219 8.973797 20.044218 20.044218V997.079612c0 11.070422-8.973797 20.044219-20.044218 20.044219z m-464.763294-40.088437h444.719075V532.317321H53.030989V977.035394z"
        :fill="color"
        p-id="2803"
      ></path>
    </svg>
    <svg
      t="1681010660299"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3801"
      v-else-if="name == 'rotate'"
      :width="width"
      :height="height"
    >
      <path
        d="M935.497143 659.017143a36.937143 36.937143 0 0 1-1.755429 18.505143 434.322286 434.322286 0 0 1-370.980571 337.773714C325.632 1045.650286 107.373714 876.251429 77.531429 637.293714a435.712 435.712 0 0 1 378.587428-487.131428c5.778286-1.243429 13.385143-1.901714 19.748572-1.974857-2.633143-24.137143-5.851429-46.518857-7.899429-66.267429-3.291429-25.526857-6.582857-44.617143-6.582857-50.980571-1.974857-7.021714-1.389714-15.36 3.657143-21.065143a21.796571 21.796571 0 0 1 31.305143-4.608l0.585142 1.243428 17.993143 16.530286 126.683429 98.962286 17.92 13.312 15.36 11.410285c5.12 3.803429 7.68 8.850286 8.996571 14.628572a20.260571 20.260571 0 0 1-4.388571 16.603428l-11.410286 15.36-13.312 17.92L555.885714 337.92l-14.555428 18.578286-1.243429 0.658285a23.625143 23.625143 0 0 1-31.890286 3.291429c-5.778286-5.12-9.654857-12.726857-8.411428-19.748571-1.316571-5.705143-3.291429-25.453714-6.582857-50.980572-2.633143-17.846857-5.266286-38.838857-7.899429-59.904-6.436571 0-13.385143 1.974857-19.748571 1.974857-193.828571 24.502857-332.726857 201.801143-308.297143 395.556572 24.502857 193.828571 201.801143 332.726857 395.556571 308.224a353.426286 353.426286 0 0 0 303.616-281.307429 40.082286 40.082286 0 0 1 34.377143-30.134857c22.235429-3.291429 43.373714 13.165714 44.763429 34.889143z"
        :fill="color"
        opacity=".801"
        p-id="3802"
      ></path>
    </svg>
  </span>
</template>

<script>
export default {
  name: "svgIndex",
  props: {
    name: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: 20
    },
    height: {
      type: [Number, String],
      default: 20
    },
    color: {
      type: String,
      default: "#1d21ee"
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>
<style lang="stylus" scoped></style>
